<style lang="scss" scoped>
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
.btn_box {
	padding: 0 0.3rem;
	margin-top: 1rem;

	.loading_btn {
		border-radius: 8px;
		height: 0.8rem;
	}

	.btn {
		margin: 0rem 0.3rem 0.31rem;
		height: 0.8rem;
		border-radius: 8px;
		font-size: 0.3rem;
		text-align: center;
		color: #fff;
		background-color: $color_main;
		line-height: 0.8rem;
	}
}

.list {
	margin-top: 40px;

	.row_header {
		font-weight: bold;

		.plain {
			margin-left: 10px;
		}
	}

	.row {
		.label {
			color: $font_color_info;
			font-size: 14px;
			margin-right: 4px;
		}

		.btn {
			margin: 0.1rem 0 0.2rem 0;
			height: 0.8rem;
			border-radius: 4px;
			font-size: 0.3rem;
			text-align: center;
			color: #fff;
			line-height: 0.8rem;
		}
	}
}
.van-radio-group {
	display: flex;
	justify-content: center;
	font-size: 0.2667rem;
	color: $font_color_info;
	margin: 0.2667rem 0;
}
.title_name {
	color: $font_color_main;
}
.order_search {
	height: 100vh;
	background-color: $background_color2;
	padding: 0 0.4rem;
	box-sizing: border-box;
}
.van-cell-group {
	background-color: $background_color2;
}
.van-cell {
	background-color: $background_color2;
	border-bottom: 0.02rem solid $b_border_color;
	padding: 0.2rem 0;
	margin-bottom: 0.2rem;
	color: $color_main;
}
:deep(.van-field__control::-webkit-input-placeholder) {
	color: $b_font_color_info1;
}

.color_info {
	color: rgba(0, 0, 0, 0.45);
}
</style>

<template>
	<div class="order_search">
		<!-- 订单查询 -->
		<top-nav ref="nav" bgColor="#F6F6FB" :showBack="false"
			><span class="title_name">{{ $t('pFileSearch') }}</span></top-nav
		>
		<van-radio-group v-model="radio" direction="horizontal">
			<van-radio name="1"><span :class="radio == 1 ? 'title_name' : 'color_info'">投保人查询</span></van-radio>
			<van-radio name="2"><span :class="radio == 2 ? 'title_name' : 'color_info'">被保人查询</span></van-radio>
		</van-radio-group>
		<van-form class="form" ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="false">
				<van-field label="姓名" v-model="userInfo.name" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.name" :placeholder="namePlaceholder" />
				<van-field label="证件号码" v-model="userInfo.cardNum" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" :rules="rules.cardNum" :placeholder="assuredCardNo" />
				<van-field label="保单标识" v-model="userInfo.bdNum" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" :rules="rules.bdNum" :placeholder="assuredBdNum" />
			</van-cell-group>
		</van-form>

		<div class="btn_box">
			<van-button class="loading_btn" @click="search" :loading="loading" type="info" color="#2594EF" block="" size="normal" :loading-text="$t('searching')">{{ $t('search') }}</van-button>
		</div>
		<van-list class="list">
			<van-cell v-for="(item, index) in list" :key="index">
				<div class="row row_header">
					<span class="name" v-text="item.proName"></span>
					<span class="plain" v-text="item.comboName"></span>
				</div>
				<div class="row">
					<span class="label">保障期限：</span>
					<span v-text="`${item.enableDate} 至 ${item.disEnableDate}`"></span>
				</div>
				<div class="row">
					<span class="label">支付时间：</span>
					<span v-text="item.createTime"></span>
				</div>
				<div class="row row_btn" v-if="item.urlPath">
					<van-button class="btn" type="primary" size="mini" @click="download(item.urlPath)">下载保单 </van-button>
				</div>
			</van-cell>
		</van-list>
		<fileDownload :show.sync="show" :url="fileUrl" @update:show="val => (show = val)" name="保单" />
	</div>
</template>

<script>
import { Cell, CellGroup, Form, Field, Button, List, Toast, RadioGroup, Radio } from 'vant';
import { selectPolicy } from '@/request/cps';
import fileDownload from '@/components/fileDownload.vue';

export default {
	name: 'cpspFileSearch', // 保单查询
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Form.name]: Form,
		[Button.name]: Button,
		[List.name]: List,
		[Radio.name]: Radio,
		[RadioGroup.name]: RadioGroup,
		fileDownload,
	},
	data() {
		return {
			userInfo: {
				name: '',
				cardNum: '',
				bdNum: '',
			},
			rules: {
				name: [{ required: true, message: '' }],
				cardNum: [{ required: true, message: '' }],
				bdNum: [{ required: true, message: '' }],
			},
			loading: false,

			list: [],

			radio: '1',
			namePlaceholder: '投保人姓名',
			assuredCardNo: '投保人证件号码',
			assuredBdNum: '可为保单号、投保单号',
			show: false,
			fileUrl: '', //文件下载地址
		};
	},
	watch: {
		radio: {
			handler(newVal) {
				if (newVal === '2') {
					this.namePlaceholder = '被保人姓名';
					this.assuredCardNo = '被保人证件号码';
				} else if (newVal === '1') {
					this.namePlaceholder = '投保人姓名';
					this.assuredCardNo = '投保人证件号码';
				}
			},
		},
	},
	methods: {
		search() {
			this.$refs.form.validate().then(async () => {
				this.loading = true;
				const res = await selectPolicy({
					type: this.radio,
					name: this.userInfo.name,
					certificateContent: this.userInfo.cardNum,
					policyFlag: this.userInfo.bdNum,
				});
				if (!res.length) {
					Toast('未查询到信息');
				}
				this.list = res;
				this.loading = false;
			});
		},

		download(url) {
			// window.open(url, '_blank');
			this.show = true;
			this.fileUrl = url;
		},
	},
};
</script>
